import VueI18n from 'vue-i18n';
import Vue from 'vue'
import zhLocale from './zh'
import enLocale from './en'
import {getCookie} from "@/utils/cookieUtil";

Vue.use(VueI18n);

const message={
    en:{
        ...enLocale
    },
    zh: {
        ...zhLocale
    }
}

const i18n=new VueI18n({
    // locale : 'zh',
    locale : getCookie("language")===undefined?'zh':getCookie("language"),
    messages : message
})

export default i18n;
