<template>
	<div class="header-wrapper" >
		<div class="header">
			<div class="header-left" >
				<img src="../../assets/images/logo.png" alt="" style="width: 200px; height: 100px;position: relative;top: -20px; left: -80px">
			</div>
			<div class="header-mid">
				<div class="header-item">
          <div style="display: flex;flex-direction: row;align-items: center;">
            <i style="color:#7c6aa6" class="el-icon-message"></i>
            <div class="item">
              <strong>chenmemglong@gmail.com</strong>
            </div>
          </div>
          <div style="display: flex;flex-direction: row;align-items: center;">
            <i style="color:#7c6aa6" class="contact-icon am-icon-phone"></i>
            <div class="item">
              <strong>+86 137-9149-9100</strong>
            </div>
          </div>
				</div>
				<div class="header-item" style="display: flex; flex-direction: row">
					<i style="color:#7c6aa6" class="contact-icon am-icon-map-marker"></i>
					<div class="item">
						<strong>{{ $t('location') }}</strong>
						<span>{{ $t('CompanyName') }}</span>
					</div>
				</div>
			</div>
<!--			<div class="header-right">-->
<!--				<a href="tel:+18811711583" class="">-->
<!--					<button type="button" >{{ $t('header.contactUs') }}</button>-->
<!--				</a>-->
<!--			</div>-->
      <div style="position: relative; left: 30px">
        <el-switch
            @change="changeLanguage"
            v-model="language"
            active-text="中文"
            inactive-text="English">
        </el-switch>
      </div>
		</div>

		<div class="nav-wrapper header-default">
			<div class="nav">
				<ul class="am-nav am-nav-pills am-nav-justify">
          <li><router-link class="router" to="/index">{{ $t('header.HelloPage') }}</router-link></li>
          <li><router-link class="router" to="/product">{{ $t('header.productCenter') }}</router-link></li>
<!--					<li><router-link class="router" to="/example">{{ $t('header.customerCase') }}</router-link></li>-->
<!--					<li><router-link class="router" to="/news">{{ $t('header.companyDynamics') }}</router-link></li>-->
					<li><router-link class="router" to="/about">{{ $t('header.aboutHengXin') }}</router-link></li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
import AppFunctions from "@/utils/AppFunctions";
import {getCookie, getLocalStorage, setCookie, setLocalStorage} from "@/utils/cookieUtil";
import {getImUser} from "@/api/app";


export default {
	name: "Header_page",
	components: {},
	data(){
		return{
      language: true,
		}
	},

	created() {
		window.addEventListener('scroll', this.toggleStickyHeader);
    this.language=this.$i18n.locale === 'zh';
    if(getLocalStorage("im_user")===null || getLocalStorage("im_user")===undefined){
      getImUser().then(res=>{
        if(res.code===200){
          setLocalStorage("im_user",JSON.stringify(res.data))
        }
      })
    }
	},

	mounted() {
		this.toggleStickyHeader();
	},

	beforeDestroy() {
		window.removeEventListener('scroll', this.toggleStickyHeader);
	},

  methods: {
    changeLanguage() {
      let language=getCookie("language")
      if(language==='en'){
        this.$i18n.locale='zh'
        setCookie("language","zh")
        this.$router.go(0);
        return true;
      }else{
        setCookie("language","en")
        this.$i18n.locale='en'
        this.$router.go(0);
        return false
      }
      // 刷新页面
    },
    toggleStickyHeader() {
      const scrolled = document.documentElement.scrollTop;
      if (scrolled > 100) {
        AppFunctions.addClass('.header-default', 'sticky');
      } else if (scrolled <= 100) {
        AppFunctions.removeClass('.header-default', 'sticky');
      }
    },
  },

}
</script>

<style scoped>
/* 页面头部 */
.header {
  height: 200px;
}


/* Add underline effect on hover */
.nav li:hover::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #7c6aa6; /* Change this to the desired color */
  bottom: 0;
  left: 0;
}
</style>
