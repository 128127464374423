import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './assets/css/style.css'
import {getRequest} from "@/utils/request";
import i18n from "@/language";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import "../src/icons";  //icon
Vue.config.productionTip = false

Vue.use(ElementUI);
Vue.prototype.getRequest = getRequest;

Vue.config.productionTip = false

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
