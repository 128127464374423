<template>
    <div class="toolbar1" style="right: 30px;top:510px;">
        <el-tooltip class="item" effect="dark" :content="$t('sideBar.onlineConsultation')" placement="left">
            <a href="javascript:void(0)" @click="handleGoIm" class="toolbar_item chat hand-style" style="color: black; font-style: italic; font-weight: bold;">
              <div class="custom-button">
                在线咨询
                <el-badge :value="unReadCount" v-if="unReadCount" class="badge" style="float: right;" />
                <svg-icon icon-class="consult" style="margin-left: 5px; height: 60px; width: 60px"></svg-icon>
              </div>
            </a>
        </el-tooltip>
    </div>
</template>
<script>
import bus from '@/utils/bus'
export default {
    data() {
        return {
            show: false,
            unReadCount:0
        }
    },
    created() {
      //事件线，如果有消息过来，增加角标显示
      bus.$on('custom-event',(count)=>{
        this.unReadCount=count
      })
    },
    mounted() {
    },

    methods: {
        handleGoIm() {
            this.$router.push({ path: "/im" })
        },
    },
}
</script>

<style lang="scss" scoped>
.toolbar1 {
    position: fixed;
    bottom: 130px;
    z-index: 99;
    transition: all .8s;
    margin-top: 50px;

    .toolbar_item {
        margin-top: 100px;
        width: 100px;
        height: 100px;
        text-align: center;
        margin-bottom: 10px;
        display: block;
        font-size: 1.2rem;
        text-decoration: none;
        color: rgb(55, 58, 89);
        padding: 5px;
        border-radius: 5px;
        font-weight: 100;
        position: relative;
        overflow: hidden;

        .theme {
            position: absolute;
            left: 10px;
            top: 10px;
            transition: all .5s;
        }
    }

  .custom-button {
    background-color: #f0f0f0; /* 设置白色背景 */
    border-radius: 10px; /* 可选，添加圆角 */
  }

}
</style>
