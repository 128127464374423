<template>
	<div class="footer">
		<div style="background-color:#383d61" class="footer--bg"></div>
		<div class="footer--inner">
			<div class="am-g">
				<div class="am-u-md-3 ">
					<div class="footer_main--column">
						<strong class="footer_main--column_title">{{ $t('header.productCenter') }}</strong>
              <ul class="footer_navigation">
                <li class="footer_navigation--item"><router-link to="/product" class="footer_navigation--link">{{ $t('footer.productName') }}</router-link></li>
              </ul>
					</div>
				</div>
				<div class="am-u-md-6 ">
					<div class="footer_main--column">
						<strong class="footer_main--column_title">{{ $t('footer.aboutUs') }}</strong>
						<div class="footer_about">
							<p class="footer_about--text">{{ $t('footer.introduce') }}
							</p>
						</div>
					</div>
				</div>
				<div class="am-u-md-3 ">
					<div class="footer_main--column" style="width: 300px">
						<strong class="footer_main--column_title">{{ $t('footer.contactDetails') }}</strong>
						<ul class="footer_contact_info">
							<li class="footer_contact_info--item"><i class="el-icon-message"></i><span>{{ $t('footer.mail') }}chenmemglong@gmail.com</span></li>
							<li class="footer_contact_info--item"><i class="am-icon-phone"></i><span>{{ $t('footer.serviceLine') }}：137 9149 9100</span></li>
							<li class="footer_contact_info--item"><i class="am-icon-map-marker"></i><span>{{ $t('location') }}</span></li>
							<li class="footer_contact_info--item"><i class="am-icon-clock-o"></i><span>{{ $t('footer.time') }}</span></li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "FooterView"
}
</script>

<style scoped>

</style>
