import request from '@/utils/request'

// 聊天室接口
export function getImUser() {
    return request({
        url: '/im/imUser',
        method: 'get',
    })
}

