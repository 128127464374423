export default {
    location:"Cao County, Heze City, Shandong Province",
    CompanyName:"Hengxin renewable resources Co., LTD",
    homePage:"Home page",
    productCenter:"Product center",
    aboutUs:"about us",
    header:{
        HelloPage:"Home page",
        productCenter:"Product center",
        customerCase:"Customer case",
        companyDynamics:"Company dynamics",
        aboutHengXin:"About HengXin",
        contactUs:"Contact us",
    },
    footer:{
        productCenter:"Product center",
        productName:"Wood fiber powder",
        aboutUs:"About us",
        introduce:"Hengxin renewable resources Co., LTD is a wood fiber powder supplier in Shandong, China, established in 2019.",
        contactDetails:"contact details",
        mail:"mail",
        serviceLine:"service line",
        time:"Monday to Sunday, 8:00-23:00",
    },
    indexView:{
        productName:"Wood fiber powder",
        applicationArea:"application area",
        orderingChannel:"Ordering channel",
        productCenter:"Product center",
        serviceSupport:"服务支持",
        learnMore:"learn more",
    },
    productView:{
        productName:"Wood fiber powder",
        productDesc:"Wood powder, the appearance is powdery, the color is white, close to the whiteness of white paper, the specific whiteness detection value is 80",
        productIntroduction:"Product Introduction",
        //两个标题
        productFeature:"product feature",
        productUsage:"product usage",
        //产品特征
        feature:"feature",
        content:"content",
        //产品用途
        artificialLeatherAdditive:"Artificial leather additive",
        artificialLeatherAdditiveIntroduction_1:"This product is fine, low formaldehyde content, no pollution, environmental protection, low cost, often used as an additive for artificial leather",
        artificialLeatherAdditiveIntroduction_2:"It can make the surface of artificial leather smoother and more durable",
        fillers:"Pet supplies/household fillers",
        fillers_1:"This product can be used as filler for pet products such as cat litter, toothpaste, powder cosmetics, and PVC and other hard plastics, plastic shells, plastic products",
        fillers_2:"It can also be used as a filler for polish and so on",
        industry:"Used in the construction industry",
        industry_1:"This product has also been widely used in the construction industry",
        industry_2:"For example, in the adhesive, sewing agent, internal and external wall putty, interface agent, mortar and gypsum and other products, the appropriate amount of this product can better control the shrinkage and crack generation",
        industry_3:"All aspects of use is very wide and the market has been very mature"
    },
    aboutView: {
        aboutUs:"about us",
        purpose:"Our Aim",
    },
    sideBar:{
        onlineConsultation:"Online consultation",
    },
    ImRoom:{
        loadMore:"Load more",
        IPTerritory:"IP Territory",
        withdrawn:"Withdrawn a message",
        read:"read",
        unread:"unread",
        saySomething:"Say something",
        send:"send",
        copy:"copy",
        translate:"translate",
        withdraw:"withdraw",
        search:"Search for it",
        baidu:"Baidu search",
        google:"Google search",
    }
}
