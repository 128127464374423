import Cookies from 'js-cookie'

export function setCookie(key,value,expire) {
    return Cookies.set(key, value, { expires: expire });
}
export function getCookie(key) {
    return Cookies.get(key);
}
export function delCookie(key){
  return Cookies.remove(key);
}

export function setSessionStorage(key,value) {
    return window.sessionStorage.setItem(key, value)
}
export function getSessionStorage(key) {
    return window.sessionStorage.getItem(key)
}


export function setLocalStorage(key,value){
  return window.localStorage.setItem(key,value)
}
export function getLocalStorage(key){
  return window.localStorage.getItem(key)
}
