import axios from "axios";
import {getCookie} from "@/utils/cookieUtil";

// let base = 'http://localhost:8888';
let base = 'http://47.99.118.193:8888';

// 创建axios实例
const service = axios.create({
    baseURL: base,
    timeout: 5000, // 请求超时时间
});

// 请求拦截器
service.interceptors.request.use(
    config => {
        // 在发送请求之前可以做一些处理，例如添加请求头
        let language=getCookie("language")
        if(language!==undefined)
            config.headers['Language']=language
        else config.headers['Language']="zh"
        return config;
    },
    error => {
        // 对请求错误做些什么
        return Promise.reject(error);
    }
);

// 响应拦截器
// response interceptor
service.interceptors.response.use(
    //获取响应结果
    response => {
            const res = response.data
            if (res.code !== 200 && res.code!==undefined) {
                    return Promise.reject(new Error(res.message || 'Error'))
            } else {
                    return res
            }
    },
    error => {
            console.log("出现了错误，error="+JSON.stringify(error))
            return Promise.reject(error)
    }
)

// 传送json格式的get请求
export const getRequest = (url, params) => {
    return service({
        method: 'get',
        url: `${url}`,
        data: params,
    });
};

export default service; // 可以导出这个实例供其他模块使用
