export default {
    location:"山东省菏泽市曹县",
    CompanyName:"恒鑫再生资源有限公司",
    homePage:"首页",
    productCenter:"产品中心",
    aboutUs:"关于我们",
    header:{
        HelloPage:"首页",
        productCenter:"产品中心",
        customerCase:"客户案例",
        companyDynamics:"公司动态",
        aboutHengXin:"关于恒鑫",
        contactUs:"联系我们",
    },
    footer:{
        productCenter:"产品中心",
        productName:"木质纤维粉",
        aboutUs:"关于我们",
        introduce:"恒鑫再生资源有限公司(Hengxin renewable resources Co., LTD) 是中国山东木质纤维粉供应商，成立2019年.",
        contactDetails:"联系详情",
        mail:"邮箱",
        serviceLine:"服务专线",
        time:"周一 ~ 周日, 8:00 - 23:00"
    },
    indexView:{
        productName:"木质纤维粉",
        applicationArea:"应用领域",
        orderingChannel:"订购渠道",
        productCenter:"产品中心",
        serviceSupport:"服务支持",
        learnMore:"了解更多",
    },
    productView:{
        productName:"木质纤维粉",
        productDesc:"木粉，外观呈粉末状，颜色为白色，接近白纸的白度，具体白度检测值为80",
        productIntroduction:"产品介绍",
        //两个标题
        productFeature:"产品特征",
        productUsage:"产品用途",
        //产品特征
        feature:"特征",
        content:"内容",
        //产品用途
        artificialLeatherAdditive:"人造皮革添加剂",
        artificialLeatherAdditiveIntroduction_1:"本产品细度比较细，甲醛含量低，无污染，环保，成本低,常用于做人造皮革的添加剂",
        artificialLeatherAdditiveIntroduction_2:"可以使使人造革表面更光滑更耐用",
        fillers:"宠物用品/日用品填充物",
        fillers_1:"本产品可以做猫砂等宠物用品的填充物、做牙膏的填充物、做粉末状化妆品的填充物以及做PVC等硬塑料、塑料外壳、塑料制品的填充物",
        fillers_2:"还可以做抛光剂的填充物等等",
        industry:"应用于建筑行业",
        industry_1:"本产品在建筑行业里也已广泛应用",
        industry_2:"比如在胶粘剂、缝缝剂、内外墙腻子、界面剂、砂浆和石膏等产品中适量加入本产品可以更好的控制收缩和裂缝的产生",
        industry_3:"各方面用途十分广泛且市场已经很成熟"
    },
    aboutView: {
        aboutUs:"关于我们",
        purpose:"我们的宗旨",
    },
    sideBar:{
        onlineConsultation: "在线咨询",
    },
    ImRoom:{
        loadMore:"加载更多",
        IPTerritory:"IP属地",
        withdrawn:"撤回了一条消息",
        read:"已读",
        unread:"未读",
        saySomething:"说点什么呢",
        send:"发送",
        copy:"复制",
        translate:"翻译",
        withdraw:"撤回",
        search:"搜一搜",
        baidu:"百度搜索",
        google:"谷歌搜索",
    }
}
